








import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Placeholder that should be shown when it's not possible to render the module itself.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ModuleUnavailablePlaceholder>({
  name: 'ModuleUnavailablePlaceholder',
  components: {
    UiAlert: () => import(
      /* webpackChunkName: "atoms/UiAlert" */
      '../../atoms/UiAlert/UiAlert.vue'
    )
  }
})
export class ModuleUnavailablePlaceholder extends Vue {
  /**
   * Driver of the module that the placeholder corresponds to.
   */
  @Prop({ type: String, required: true })
  public readonly moduleDriver!: string

  /**
   * Title of the module that the placeholder corresponds to.
   */
  @Prop({ type: String, required: false })
  public readonly moduleTitle?: string

  /**
   * Translated value for `this.moduleDriver` or placeholder info,
   * if the driver hasn't been specified.
   * @see moduleDriver
   *
   * Used as a value for the named formatting feature of the VueI18n.
   * @see https://kazupon.github.io/vue-i18n/guide/formatting.html#named-formatting
   */
  public get driver (): string {
    return this.moduleDriver
      ? this.$t(`moduleDrivers.${this.moduleDriver}`) as string
      : `<${(this.$t('_.unknown-driver') as string).toLowerCase()}>`
  }

  /**
   * Value from `this.moduleTitle` or placeholder info, if the title hasn't been specified.
   * @see moduleTitle
   *
   * Used as a value for the named formatting feature of the VueI18n.
   * @see https://kazupon.github.io/vue-i18n/guide/formatting.html#named-formatting
   */
  public get title (): string {
    return this.moduleTitle || `<${(this.$t('_.untitled-module') as string).toLowerCase()}>`
  }
}

export default ModuleUnavailablePlaceholder
